import React from 'react'
import { graphql } from 'gatsby'
// eslint-disable-next-line
import PropTypes from 'prop-types'

import {
  Heading,
  Button,
  Container,
  Hero,
  // Icon,
  Section,
  Box,
  Content
} from 'react-bulma-components'

import Layout from '../components/layouts/index'
import SEO from '../components/seo'

import ArtPortfolioSectionBg from '../components/ArtPortfolioSectionBg'

import { Tablet, Mobile } from '../utils/presets'

// eslint-disable-next-line
const IconBox = ({ children, ...props }) =>
  (
  <div
    css={{
      display: 'flex',
      backgroundColor: '#F1F2FD',
      borderRadius: 6,
      padding: '1rem',
      margin: '.5rem',
      width: 65
    }}
    {...props}
  >
    {children}
  </div>
)

// eslint-disable-next-line
const DescriptionBlock = ({ title, description, ...props }) =>
(
  <div
    css={{
      display: 'flex'
    }}
    {...props}
  >
    <p css={{ color: '#5B59FB', fontWeight: 'bold' }}>{title}</p>
  </div>
)

class PrivacyPolicy extends React.Component {
  getImage(imageName) {
    return this.props.data[imageName].childImageSharp.fluid
  }
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        bodyInContainer={false}
        className="index"
      >
        <SEO title="Privacy notice" />
        <Hero size="small">
          <Hero.Body className="is-paddingless">
            <ArtPortfolioSectionBg style={{ minHeight: 200 }}>
              <Container className="has-text-centered">
                <Section>
                  <Heading size={1} renderAs="h1">
                    Privacy Notice
                  </Heading>
                </Section>
              </Container>
            </ArtPortfolioSectionBg>
          </Hero.Body>
        </Hero>
        <Hero size="small">
          <Hero.Body className="is-paddingless">
            <Container className="has-text-left">
              <Section>
                <Heading size={6} renderAs="h5">
                  Last updated: 20/03/2020
                </Heading>
                <Content>
                  <p>
                    We care about the privacy of your data and we are committed
                    to protecting it. This Privacy Notice (“Notice”) is here to
                    help you to understand how your personal data is collected,
                    stored or used, and what happens to it when you&apos;re
                    using tangibly.tech (“Website”).
                  </p>
                  <Heading size={2} renderAs="h2">
                    1. Who we are?
                  </Heading>
                  <p>
                    The data controller is Tangibly OU reg. No.14890173 Address:
                    Harju maakond, Tallinn, Kesklinna linnaosa, Sakala tn 7-2,
                    10141. In this document, we will call ourselves
                    &quot;Tangibly&quot;, &quot;we&quot; or &quot;ours&quot;.
                    You can write to our e-mail {this.decodeSupportURL()} if you
                    have any questions regarding your personal data. Also, you
                    can write to us at the mailing addresses listed above.
                  </p>
                  <Heading size={2} renderAs="h2">
                    2. How we collect and use data?
                  </Heading>
                  <p>
                    When you access our Website we will automatically receive
                    certain technical data, you can provide some data yourself.
                    <br />
                    2.1. <b>Automatically collected data</b>
                    <br />
                    We need this data to operate, maintain and improve our
                    Website. Such data includes:
                    <ul>
                      <li>
                        <b>Browser and device information:</b> we receive
                        information about the device which you are using (type,
                        model etc.) also we receive information about your
                        browser (type, version, language etc.)
                      </li>
                      <li>
                        <b>Usage information:</b> we collect the information
                        relating to the usage of the Website, including your
                        actions on the Website if such actions are present;
                      </li>
                      <li>
                        <b>Geolocation information:</b> we may collect the
                        information about your geolocation (country, city) via
                        third-party services.
                      </li>
                      <li>
                        <b>Cookies and similar technologies.</b> We use cookies
                        for analytical purposes and to provide you with the
                        features of our Website. You may disable cookies in your
                        browser settings at any time. Find out more about our
                        use of cookies <a href="#cookies">further</a> in this
                        Notice, or read our Cookie Notice.
                      </li>
                    </ul>
                  </p>
                  <p>
                    2.2. <b>Data you give us</b>
                    <br />
                    Generally, you may browse our Website without providing us
                    without any of your personal data. However, if you want to
                    use all of the features of the Website, you may need to
                    provide some of your data:
                    <ul>
                      <li>
                        <b>Contact data:</b> name, email address, country.
                      </li>
                    </ul>
                  </p>
                  <Heading size={2} renderAs="h2">
                    3. Why do we use your data?
                  </Heading>
                  <p>
                    3.1. <b>Provide you with access to our Website.</b> We
                    process your personal data to provide you with access to our
                    Website so that you can view its pages and see its contents.
                    <br />
                    3.2. <b>To improve your interaction with our Website.</b> We
                    may use some of your personal data to improve your
                    interaction with our Website, including: for analytics and
                    statistics, as well as for the development of new websites.
                    <br />
                    3.3. <b>Marketing.</b> If you give us your consent, we will
                    use your data for direct marketing. You can always withdraw
                    your consent by sending us an email to{' '}
                    {this.decodeSupportURL()}.<br />
                    3.4. <b>Compliance with legal requirements.</b> We may
                    process certain personal data in order to comply with legal
                    requirements.
                  </p>
                  <Heading id="cookies" size={2} renderAs="h2">
                    4. Do we use cookies?
                  </Heading>
                  <p>
                    A cookie is a piece of data stored on the user&apos;s hard
                    drive containing information about the user. Usually, we use
                    cookies for analytical purposes. The links below provide
                    information about cookie settings for the browsers:
                  </p>
                  <ul>
                    <li>
                      <a
                        href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Internet Explorer
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Firefox
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Microsoft Edge
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://support.google.com/chrome/answer/95647"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Chrome
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Safari
                      </a>
                    </li>
                  </ul>
                  <p>
                    Also, you can opt-out by visiting the{' '}
                    <a
                      href="http://optout.networkadvertising.org/?c=1#!/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Network Advertising Initiative Opt Out
                    </a>{' '}
                    page.
                  </p>
                  <Heading size={2} renderAs="h2">
                    5. How do we share and disclose data?
                  </Heading>
                  <p>
                    We may share your personal data under the following
                    conditions:
                    <br />
                    5.1. <b>Safety, Legal purposes, and Law enforcement.</b> We
                    will disclose your personal data to third parties to the
                    extent necessary to:
                    <ul>
                      <li>
                        complywith a government request, a court order or
                        applicable law;
                      </li>
                      <li>defend ourselves against third-party claims;</li>
                      <li>assist in fraud prevention or investigation</li>
                    </ul>
                    5.2. <b>Business transfers.</b>We will not sell your
                    personal data to any company or organization. However, we
                    may transfer your personal data to a successor entity upon a
                    merger, consolidation or other corporate reorganization in
                    which we may participate or to a purchaser or acquirer of
                    all or substantially all of our assets to which this Website
                    relates. In such event, we will notify you before your
                    personal data is transferred and becomes subject to a
                    different privacy statement. You will have the opportunity
                    to refuse to transfer your data to a new controller.
                    <br />
                    5.3. <b>With your consent</b>. In cases where you have
                    provided your consent, we may share your personal data, as
                    described at the time of consent. 5.4.{' '}
                    <b>Service providers.</b> We use third parties for the
                    processing of personal data on our behalf. For example,
                    host, manage and service our data, distribute emails,
                    research, and analysis, etc. When using third-party service
                    providers we enter into agreements that require them to
                    implement appropriate technical and organizational measures
                    to protect your personal data.
                  </p>
                  <Heading size={2} renderAs="h2">
                    6. How do we handle your data?
                  </Heading>
                  <p>
                    6.1. <b>Security.</b> We provide industry-standard physical,
                    electronic, and procedural safeguards to protect personal
                    data we process and maintain. For example, we take
                    reasonable operational and technical measures to limit
                    access to your data. For example, data that we receive is
                    available only to authorized employees. If you have a reason
                    to believe that your interaction with our Website is no
                    longer secure, please immediately notify us by contacting us
                    in writing at {this.decodeSupportURL()}.<br />
                    6.2. <b>Retention of your personal data.</b> We retain your
                    personal data for as long as necessary to fulfill the
                    purposes described in this Notice. Usually, the retention
                    period of your personal data does not exceed 12 months since
                    your last visit or interaction with us.
                  </p>
                  <Heading size={2} renderAs="h2">
                    7. How can you manage your personal data?{' '}
                  </Heading>
                  <p>
                    If you would like to access, review, update, rectify, and
                    delete any personal information we hold about you, or
                    exercise any other of your rights such as a right to object
                    or restrict processing and a right to data portability, you
                    can email us to {this.decodeSupportURL()}. In case you are
                    unhappy with how we handle your data, you have a right to
                    lodge a complaint with a supervisory authority.
                  </p>
                  <Heading size={2} renderAs="h2">
                    8. Policy towards minors
                  </Heading>
                  <p>
                    We do not knowingly process personal data from anyone under
                    the age of 16. If you are a parent or a guardian and your
                    child has given us his/her personal data, please contact us
                    at {this.decodeSupportURL()}, we will delete that data as
                    soon as possible.{' '}
                  </p>
                  <Heading size={2} renderAs="h2">
                    9. How do we update this notice?
                  </Heading>
                  <p>
                    Applicable law and our practices change over time. If we
                    decide to update our Privacy Notice, we will post the
                    changes on our Website. If we materially change the way in
                    which we process your personal data, we will provide you
                    with prior notice, or where legally required, request your
                    consent prior to implementing such changes. We strongly
                    encourage you to read our Privacy Notice and keep yourself
                    informed of our practices.
                  </p>
                </Content>
              </Section>
            </Container>
          </Hero.Body>
        </Hero>
        <Hero size="small">
          <Hero.Body className="is-paddingless">
            <ArtPortfolioSectionBg style={{ minHeight: 300 }}>
              <Container>
                <Section>
                  <Box>
                    <Section
                      className="custom-section"
                      css={{
                        [Mobile]: {
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column'
                        },
                        [Tablet]: {
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          paddingTop: '1rem',
                          paddingBottom: '1rem',
                          flexDirection: 'inherit'
                        }
                      }}
                    >
                      <div>
                        <Heading size={2} renderAs="h2">
                          Let&apos;s talk
                        </Heading>
                        <Heading subtitle size={5} renderAs="h3">
                          Software development solutions that <br /> solve real
                          business needs.
                        </Heading>
                      </div>
                      <Button
                        color="primary"
                        onClick={this.openMailTo}
                        className="responsive-button"
                        css={{
                          display: 'flex',
                          fontWeight: 'bold',
                          [Mobile]: {
                            marginTop: '2rem'
                          },
                          [Tablet]: {
                            marginTop: 0
                          }
                        }}
                      >
                        GET ESTIMATE
                      </Button>
                    </Section>
                  </Box>
                </Section>
              </Container>
            </ArtPortfolioSectionBg>
          </Hero.Body>
        </Hero>
      </Layout>
    )
  }

  decodeSupportURL = () => {
    const encoded = 'ifmmpAubohjcmz/ufdi'
    let link = ''
    for (let i = 0; i < encoded.length; i++)
      link += String.fromCharCode(encoded.charCodeAt(i) - 1)

    return link
  }

  openMailTo = e => {
    e.preventDefault()
    const link = this.decodeSupportURL()

    window.location.href = `mailto:${link}`
  }
}

PrivacyPolicy.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object
}
export default PrivacyPolicy

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
